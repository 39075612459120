














































import { Observer } from "mobx-vue";
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import { TrainingGroundEventViewmodel } from "../viewmodels/training-ground-event-viewmodel";

@Observer
@Component({
  components: {
    SelectLockPeriod: () => import("./select-lock-period.vue"),
  },
})
export default class IncreaseUnlockTime extends Vue {
  @Inject() vmEvent!: TrainingGroundEventViewmodel;
  @Prop({ default: false }) hideAPR;

  @Prop({ default: 0 }) estimatedStakeAmount;
  @Prop({ default: 0 }) estimatedAPR;
  @Prop({ default: 1 }) estimatedBooster;
  @Prop({ default: 0 }) estimatedFinalAPR;
  @Prop({ default: 0 }) estimatedReward;
  @Prop({ default: "-/--" }) lockUntil;
  @Prop({ default: "-/--" }) lockTime;
}
